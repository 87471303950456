import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;
    let logoUrl;
    logoUrl = (
      <h1 className="title text-white">
        ALIEN <span className="font-weight-light">STUDIOS</span>
      </h1>
    );

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <a
                    href="/#about"
                    onClick={(e) => {
                      if (window.location.pathname === "/") {
                        e.preventDefault();
                        document
                          .querySelector("#about")
                          .scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/#services"
                    onClick={(e) => {
                      if (window.location.pathname === "/") {
                        e.preventDefault();
                        document
                          .querySelector("#services")
                          .scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="/#portfolio"
                    onClick={(e) => {
                      if (window.location.pathname === "/") {
                        e.preventDefault();
                        document
                          .querySelector("#portfolio")
                          .scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Portfolio
                  </a>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <Link to="/contact" className="rn-btn">
                <span>Contact us</span>
              </Link>
            </div>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}

            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
