import React, { Component } from "react";
import {
  FiBarChart2,
  FiShare2,
  FiLayers,
  FiEdit2,
  FiTerminal,
  FiMonitor,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiBarChart2 />,
    title: "Data Analytics",
    description:
      "We provide various analytics services to align with your business needs and achieve operational enhancements, informed decision-making, optimized customer experiences, and more.",
  },
  {
    icon: <FiShare2 />,
    title: "Digital Marketing",
    description:
      "Our expertise lies in maintaining your brand's online presence and boosting sales through diverse marketing campaigns encompassing digital strategies such as web development, SEO/SEM, email and social media advertising, social media management, and performance tracking.",
  },
  {
    icon: <FiTerminal />,
    title: "Web Development",
    description:
      "We understand the significance of your website as a vital business tool, and we prioritize elements like visual design, navigation, information accessibility, and interaction while incorporating your brand initiatives.",
  },
  {
    icon: <FiLayers />,
    title: "Shopify, Woo Commerce, Magento",
    description:
      "Building an online store for your business enables a faster buying process, cost-effective advertising and marketing, unlimited reach, agility to respond to market demands, and overall cost reduction, and we are ready to demonstrate these benefits to you.",
  },
  {
    icon: <FiMonitor />,
    title: "Lead Generation and Appointment Setting",
    description:
      "We assist businesses in attracting and qualifying potential customers, serving as a valuable resource for sales teams, and offer dependable partnership for scheduling consultations between your sales staff and prospective clients to ensure crucial meetings are booked.",
  },
  {
    icon: <FiEdit2 />,
    title: "Creative Design",
    description:
      "We recognize the importance of design principles and visuals, including branding, ideation, UX, brand identity assets, online banners, social media ads, and product packaging, in inspiring and captivating customers' attention while enhancing profitability.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "We are focused on a very pragmatic modernist approach, efficient documentation, and high speed response.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark" href="/contact">
                  <span className="text">Request A Service</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-12" key={i}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
