import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "About",
      description =
        "Applying thought. we are well-versed with a talent pool consisting of software programmers, web / ecommerce developers, digital marketing specialists, lead generators and appointment setters working with just about any technology that a small business would encounter. we use this expertise to help customers with small to mid-sized projects.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-1.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          We are a dynamic team of digital marketing experts and
                          tech enthusiasts, driving business success through
                          innovative solutions.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">What we do</h3>
                        <p>
                          Our agency harnesses cutting-edge tools and strategies
                          to create impactful online campaigns, optimize web
                          presence, and maximize conversions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
