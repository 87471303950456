import React, { Component } from "react";

const ServiceList = [
  {
    icon: "01",
    title: "Business Strategy",
    description:
      "Aligning goals, optimizing operations, and driving growth through strategic planning and implementation.",
  },
  {
    icon: "02",
    title: "Website Development",
    description:
      "Expertly crafted websites with intuitive design, seamless navigation, and superior functionality.",
  },
  {
    icon: "03",
    title: "Marketing & Reporting",
    description:
      "Market planning, execution, digital advertising, social media management, and performance analysis.",
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div
                  className="icon"
                  style={{
                    filter:
                      "invert(100%) sepia(0%) hue-rotate(0deg) brightness(103%) contrast(103%)",
                  }}
                >
                  <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="Digital Agency"
                  />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
