import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Alien Studios</title>
          <meta
            name="description"
            content="Making Digital Accessible. Technology Solutions for Every Business."
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
