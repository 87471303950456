import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 contact-left">
              <div className="section-title text-left mb--50">
                <h2 className="title">Lets start a Strategic Partnership!</h2>
                <div className="contact-block my-5">
                  <h4>Reach us at</h4>
                  <h5 className="mb-1">Canada</h5>
                  <p>
                    7680 Black Walnut Trail Mississauga, Ontario, Canada L5N 8A6
                    <br />
                    <a href="mailto:support@ideasy-tech.com">
                      support@ideasy-tech.com
                    </a>
                  </p>
                </div>
                <div className="contact-block my-5">
                  <h4>Write to us</h4>
                  <p>
                    For sales:{" "}
                    <a href="mailto:sales@ideasy-tech.com">
                      sales@ideasy-tech.com
                    </a>
                  </p>
                  <p>
                    For Human Resources:{" "}
                    <a href="mailto:hr@ideasy-tech.com">hr@ideasy-tech.com</a>
                  </p>
                  <p>
                    For Support:{" "}
                    <a href="mailto:support@ideasy-tech.com">
                      support@ideasy-tech.com
                    </a>
                  </p>
                </div>
                <p className="description"></p>
              </div>
            </div>

            <div className="col-lg-6 contact-right">
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
